@import url("/src/vendor/fonts/fonts.css");
@import url("/src/vendor/normalize.css");

* {
  box-sizing: border-box;
}

#root {
  background: #0e0e0e;
}

.page {
  max-width: 1440px;
  padding: 0 64px;
  margin: 0 auto;
  text-align: center;
  font-family: "Tahoma", "Arial", sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1 {
  font-size: 57px;
  line-height: 69px;
  font-family: "Tahoma", sans-serif;
  text-transform: uppercase;
  margin: 0;
}

h2 {
  font-size: 53px;
  line-height: 63px;
  font-family: "Tahoma", sans-serif;
  margin: 15px 0 25px;
}

h3 {
  font-size: 31px;
  line-height: 43px;
  font-family: "Tahoma", sans-serif;
  margin: 15px 0 10px;
}

.t-23 {
  font-size: 23px;
  line-height: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0;
}

.t-17 {
  font-size: 17px;
  line-height: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0;
}

.t-14 {
  font-size: 14px;
  line-height: 21px;
  font-family: "Carmen", sans-serif;
  margin: 0;
}

.col-gr {
  color: #00eeb5;
}

.bg-gr {
  background-color: #00eeb5;
}

.bg-yel {
  background-color: #ffd60a;
}

.pd-bot {
  padding-bottom: 80px;
}

.btn {
  height: 46px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.5s;
}

.privacy {
  text-align: left;
}

.privacy__mg {
  margin-bottom: 15px;
}

@media (max-width: 950px) {
  .page {
    padding: 0 25px;
  }

  .pd-bot {
    padding-bottom: 50px;
  }

  h1 {
    font-size: 48px;
    line-height: 59px;
  }
  
  h2 {
    font-size: 43px;
    line-height: 53px;
  }
  
  h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

@media (max-width: 749px) {
  h1 {
    font-size: 40px;
    line-height: 49px;
  }
  
  h2 {
    font-size: 33px;
    line-height: 40px;
  }
  
  h3 {
    font-size: 21px;
    line-height: 30px;
  }
}
