.main__cover {
  margin: -80px -64px 80px;
  display: flex;
}

.main__text-container {
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.main__header-container {
  padding: 0 50px 30px 64px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
}

.main__paragraph {
  font-weight: 700;
}

.main__description {
  padding: 30px 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
  margin: 0;
}

.description__item {
  color: black;
  position: relative;
  padding-left: 25px;
  text-align: left;
}

.description__item::before {
  position: absolute;
  content: "\25CF";
  left: 0;
  top: 25%;
}

.main__image-container {
  width: 50%;
}

.main__image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 950px) {
  .main__cover {
    margin: -80px -25px 50px;
    display: flex;
  }

  .main__header-container {
    margin-top: 50px;
  }
}

@media (max-width: 749px) {
  .main__cover {
    flex-direction: column;
    margin-top: 0;
  }

  .main__text-container,
  .main__image-container {
    width: 100%;
  }

  .main__header-container {
    padding: 20px 25px;
    margin: 0;
  }

  .main__description {
    padding: 20px 30px;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .description__item::before {
    top: 0;
  }
}
