.about-us {
  display: flex;
}

.about__text-container {
  width: 50%;
  text-align: left;
  padding-right: 30px;
}

.about__image-container {
  width: 570px;
  display: flex;
  flex-direction: column;
}

.about__img-1 {
  text-align: left;
}

.about__img-2 {
  text-align: right;
  margin-top: -100px;
}

.about__img-3 {
  text-align: left;
  margin-top: -100px;
}

@media (max-width: 749px) {
  .about-us {
    flex-direction: column;
  }

  .about__text-container,
  .about__image-container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .about__image {
    width: 100%;
    height: auto;
  }

  .about__image-container {
    margin: 20px auto 0;
  }
  .about__img-1 img {
    width: 156px;
  }

  .about__img-2,
  .about__img-3 {
    margin-top: -50px;
  }

  .about__img-2 img,
  .about__img-3 img {
    width: 213px;
  }

  .about__image-container {
    width: 330px;
  }
}
