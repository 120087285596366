.contacts {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0 -64px;
  padding: 0 64px;
  text-align: left;
}

.contacts__form {
  padding: 40px 30px 40px 0;
}

.contacts__header, .contacts__paragraph {
  color: black;
}

.contacts__image-container {
  display: flex;
}

.contacts__img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.form__input-container {
  display: flex;
  gap: 30px;
  margin: 30px 0;
}

.form__input {
  background-color: #0dd8a7;
  outline: none;
  height: 58px;
  border: none;
  border-radius: 20px;
  padding: 0 24px;
  width: 100%;
}

.form__input::placeholder {
    color: white;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    opacity: 0.6;
}

.form__input:focus {
    border: 2px solid #ffd60a;
}

.form__btn {
  width: 201px;
  background: #ffd60a;
  color: black;
  border: none;
}

@media (max-width: 950px) {
  .contacts {
    margin: 0 -25px;
    padding: 0 25px;
  }
}

@media (max-width: 749px) {
  .contacts {
    display: flex;
    flex-direction: column;
  }

  .form__input-container {
    flex-direction: column;
  }

  .contacts__form {
    padding: 30px 0;
  }
}
