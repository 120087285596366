.footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 10px;
  margin-top: 80px;
  text-align: left;
}

.footer__el1 {
  grid-column: 1;
  grid-row: 1 / span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__el2 {
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__el3 {
  grid-column: 2;
  grid-row: 3;
  color: white;
  align-self: end;
}

.footer__el4 {
  grid-row: 3 / span 2;
  grid-column: 1;
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer__el5 {
  grid-row: 3;
  grid-column: 3;
  justify-self: end;
  align-self: end;
  color: white;
  text-decoration: none;
}

.footer__mail-link {
  background-color: #106450;
  color: white;
  padding: 10px 31px;
  border-radius: 28px;
  width: fit-content;
}

.opacity {
  opacity: 0.4;
}

@media (max-width: 950px) {
  .footer {
    margin-top: 50px;
  }
}

@media (max-width: 749px) {
  .footer {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
  }

  .footer__el3 {
    align-self: flex-start;
  }

  .footer__el5 {
    align-self: center;
  }

  .footer__mail-link {
    margin: 15px 0;
  }
}
