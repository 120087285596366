.bars {
  text-align: left;
}

.bar {
  margin-bottom: 40px;
}

.bar__info {
  padding-top: 20px;
  border-radius: 20px;
  background-color: #212121;
  margin-top: -20px;
  position: relative;
  padding-bottom: 30px;
}

.bar__header-container {
  padding: 0 20px 20px;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
}

.bar__header {
  color: black;
}

.description__first,
.description__second {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding-bottom: 30px;
}

.bar__logo,
.foosball {
  padding-left: 20px;
}

.bar__adress {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.bar__description {
  display: flex;
  flex-direction: column;
}

.bar__rating {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.bar__activity {
  padding-right: 20px;
}

.bar__activity-container,
.foosball__list,
.slots__list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

.bar__activity-item_name {
  color: black;
  border-radius: 20px;
  width: fit-content;
  padding: 0 10px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 10px;
}

.bar__btn-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 416px;
  left: 30%;
  bottom: 20px;
}

.activate-btn {
  width: 146px;
  border: 1px solid #ffd60a;
  background: transparent;
  color: white;
  position: relative;
  text-align: left;
  padding: 0 32px;
}

.activate-btn:hover {
  border: none;
  background-color: #ffd60a;
  color: black;
}

.book-btn {
  width: 171px;
  border: none;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-btn:hover {
  border: 1px solid #ffd60a;
  background: transparent;
  color: white;
}

.activate-btn::after {
  position: absolute;
  content: "";
  background-image: url("/src/images/arrow.svg");
  right: 22px;
  height: 6px;
  width: 18px;
  top: 20px;
  transform: rotate(180deg);
}

.arrow::after {
  top: 18px;
  transform: rotate(0);
}

.list-item {
  padding: 10px 0 10px 30px;
  position: relative;
}

.list-item:before {
  position: absolute;
  left: 0;
  top: 35%;
  content: "";
  width: 14px;
  height: 14px;
  background-color: #00eeb5;
  border-radius: 50%;
}

.description__img-container {
  padding-right: 20px;
}

.description__img {
  width: 100%;
  height: auto;
}

@media (max-width: 800px) {
  .description__first,
  .description__second {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }

  .bar__logo-img {
    width: 100%;
    height: auto;
  }

  .bar__logo,
  .foosball {
    padding-left: 0;
  }

  .bar__info {
    padding-bottom: 150px;
  }

  .bar__btn-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    left: 0;
    gap: 10px;
  }
}
