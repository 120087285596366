.header__container {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
}

.header__link-container {
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
}
.header__link {
  color: #ffffff;
  text-decoration: none;
  margin-right: 30px;
}




.burger-menu {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 30px;
}

.burger__close-btn {
  display: none;
}

@media (max-width: 749px) {
  .burger-menu {
    display: flex;
  }
}

.burger-menu__nav {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #0e0e0e;
  transition: left 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.burger-menu__nav.active {
  left: 0;
}

.burger-menu__nav-container {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.burger-menu__nav-link {
  margin: 10px 0 20px;
}

.burger__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  cursor: pointer;
}

.burger-menu__nav.active .burger__close-btn {
  display: block;
}

.burger-menu.active #burger-icon {
  display: none;
}


@media (max-width: 749px) {
  .header__link-container {
    display: none;
  }

  .burger-menu {
    display: flex;
  }
}
